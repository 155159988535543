import "../css/SocialLink.css";

function SocialLink({ href, icon }) {
    return (
        <a
            href={href}
            className="social-link"
            aria-label={"Keel Mind on " + icon}
        >
            <img src={"socialLinks/" + icon + ".svg"} alt="" />
        </a>
    );
}

export default SocialLink;
