import "../css/CTAButton.css";

function CTAButton({
    large,
    short,
    href = undefined,
    onClick = () => {},
    children,
}) {
    function handleButtonKey(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            onClick();
        }
    }
    return href ? (
        <a
            href={href}
            className={`cta-button ${large ? "large" : ""} ${
                short ? "short" : ""
            }`}
        >
            {children}
        </a>
    ) : (
        <div
            tabIndex={0}
            onKeyDown={handleButtonKey}
            onClick={onClick}
            className={`cta-button ${large ? "large" : ""} ${
                short ? "short" : ""
            }`}
        >
            {children}
        </div>
    );
}

export default CTAButton;
