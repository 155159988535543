import "../css/Footer.scss";
import SocialLink from "./SocialLink";

const socials = [
    {
        icon: "instagram",
        href: "https://www.instagram.com/keel.mind/",
    },
    {
        icon: "linkedin",
        href: "https://www.linkedin.com/company/geta-head/",
    },
];

function Footer() {
    return (
        <footer>
            <div className="top">
                <img
                    src={"keel-mind-logo.webp"}
                    alt=""
                    className="header-logo"
                />
                <div className="social-links-container">
                    {socials.map((s) => (
                        <SocialLink key={s.icon} {...s} />
                    ))}
                </div>
            </div>
            <div className="bottom">
                <a href="https://www.keelmind.com/contact-us">Contact us</a>
                <a href="https://www.keelmind.com/support-directory">
                    Help center
                </a>
                <a href="https://keelmind.com/privacy-policy">Privacy policy</a>
                <a href="https://www.keelmind.com/terms-of-use">
                    Terms & conditions
                </a>
            </div>
        </footer>
    );
}

export default Footer;
