import "../css/StepCard.scss";

function StepCard({ icon, alt, heading, description }) {
    return (
        <div className="step-card">
            <img src={icon} alt={alt} />
            <p className="subtitle" role="heading" aria-level={2}>
                {heading}
            </p>
            <p>{description}</p>
        </div>
    );
}

export default StepCard;
