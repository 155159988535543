import "../css/VerticalTextSlide.scss";
// import { useState } from "react";

function VerticalTextSlide({ options = [], prefix, id }) {
    return (
        <div className={"vertical-text-slide-wrapper "} id={id}>
            <span className="prefix">{prefix}</span>
            <div className="options-container">
                {options.map((t, index) => (
                    <span key={index}>{t}</span>
                ))}
            </div>
        </div>
    );
}

export default VerticalTextSlide;
