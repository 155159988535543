import { useRef } from "react";

function VideoSection({
    video,
    image,
    color,
    className,
    children,
    videoId = "",
    videoDescription,
}) {
    const videoRef = useRef(null);
    return (
        <section
            className={`${className}-section`}
            style={{ backgroundColor: color }}
        >
            <div
                className={`${className}-container`}
                style={{
                    zIndex: -1,
                    position: "absolute",
                    // position: "relative",
                    top: 0,
                }}
            >
                <p
                    id={`${videoId}-description`}
                    style={{
                        visibility: "hidden",
                        position: "absolute",
                        pointerEvents: "none",
                    }}
                >
                    {videoDescription}
                </p>
                <video
                    // tabIndex={0}
                    aria-labelledby={`${videoId}-description`}
                    id={videoId}
                    ref={videoRef}
                    loop={true}
                    muted={true}
                    autoPlay={true}
                    playsInline={true}
                    className={`${className}-video`}
                    src={video}
                    style={{
                        backgroundImage: image,
                        minWidth: "100%",
                        position: "relative",
                        transform: "translate(-50%, -50%)",
                        left: "50%",
                        top: "50%",
                    }}
                ></video>
            </div>
            {children}
        </section>
    );
}

export default VideoSection;
