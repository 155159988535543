export const faqData = [
    {
        title: "What is Keel Mind?",
        description:
            "Keel Mind is a digital platform that provides mental health support to students at colleges and universities across Ontario at no cost. Delivered through secure text, audio, and video sessions, all appointments are confidential, easily accessible and offered online.",
    },
    {
        title: "Why do I have to use my university/college email address to register?",
        description:
            "We require your school email because this Keel Mind program is being offered to Ontario postsecondary students only. Your school email is used for signup purposes only. Any sessions or notes taken on the platform <strong>will not</strong> be shared with your school.",
    },
    {
        title: "Who can access Keel Mind mental health care support?",
        description:
            "Our mental health care support services are available to ALL students enrolled at a college or university in Ontario at no cost.",
    },
    {
        title: "How do I get matched to a mental health care provider",
        description:
            "Those seeking care simply access the portal by visiting <a class='orange' href='www.youarehere.keelmind.com'>www.youarehere.keelmind.com</a> to complete the matching question algorithm and registration process. Once completed, you are then matched with a mental health care provider based on an area of support, gender, age, and ethnicity. <em>*You can choose from several mental health care providers based on their bio and availability.</em> From here, you can quickly book a session and receive care virtually in a completely confidential and safe environment.",
    },
    {
        title: "Can I speak with a mental health care provider who understands my culture?",
        description:
            "Yes! The Keel Mind platform uses advanced technology to enable proper matching of clients to mental health care providers based on your preferences such as geography, linguistic and cultural needs.",
    },
    {
        title: "How many sessions can I access",
        description:
            "You may access as many sessions as you need to improve or resolve your mental health concerns. Some students may only need a single session, while others may benefit from ten sessions or more. Students may also return back at any point in time, if they need support again in the future.",
    },
    {
        title: "Who provides mental health support on the Keel Mind platform?",
        description:
            "Students have access to a diverse pool of care providers-in-training under supervision, tailored to their specific needs (culture, age, ethnicity, gender etc.).",
    },
    {
        title: "How can I access my counselling notes?",
        description:
            "Students have full access to their counselling notes, records, and appointment history from their Keel Mind dashboard. Students may access this information at any time by logging on to the platform.",
    },
    {
        title: "What happens if I'm matched with a care provider-in-training at my own school?",
        description:
            "Students seeking mental health support DO NOT need to see a mental health care provider offering services at their school. Students can choose from a pool of care providers-in-training (and their supervisors) across Ontario, who match their question algorithm – gender preference, age, ethnicity, language preference etc.",
    },
    {
        title: "Do I need to download an app?",
        description:
            "No, you do not need to download an app to access this service.",
    },
    {
        title: "Does it cost anything to register?",
        description:
            "No. This service is offered at <strong>no cost</strong> to all students enrolled at an Ontario college or university.",
    },
    {
        title: "Can I text my therapist?",
        description:
            "The Keel Mind platform supports video calls, voice calls and messaging. You can determine how you would like to interact with your mental health care provider.",
    },
    {
        title: "What if I don't like my mental health care provider?",
        description:
            "You may decide to switch mental health care providers at any time and can choose from a pool of care providers across Ontario.",
    },
    {
        title: "Do my parents or school have access to my counselling notes?",
        description:
            "No. Your appointments are completely confidential. Only you and your mental health care provider have access to your counselling notes.",
    },
    {
        title: "How are you able to offer this program for free?",
        description:
            "Keel Mind recognizes the critical role addiction and mental health providers play in our healthcare system and is focused on dramatically improving access to psychological services for Ontario’s postsecondary students.<br/><br/>Keel Mind would like to thank Minister Jill Dunlop and the Ministry of Colleges and Universities for their funding support as well as our organizational partners and the Ontario Psychological Association.",
    },
];
