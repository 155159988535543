import CTAButton from "../components/CTAButton";
import VideoSection from "../components/VideoSection";
import { useState } from "react";
import "../css/CACCF.scss";

function CACCFPage() {
    const [animationState, setAnimationState] = useState(true);
    function toggleAnimationHandler() {
        const video = document.querySelector("#caccf-hero-video");

        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }

        setAnimationState((prev) => {
            document
                .querySelectorAll(
                    "#home-vertical-text-slide .options-container span"
                )
                .forEach(
                    (el) =>
                        (el.style.animationPlayState = prev
                            ? "paused"
                            : "running")
                );
            return !prev;
        });
    }

    return (
        <div id="caccf-page">
            <VideoSection
                videoId="caccf-hero-video"
                video={"/home-hero-video.mp4"}
                image={"/home-hero-first-frame.webp"}
                className={"caccf-top"}
                color={"#04013f96"}
                videoDescription={
                    "A silent video of a young woman sitting on a bed. She looks off to the side, places her head on her hands and sighs. She leans back and the video blurs."
                }
            >
                <div className="caccf-top-inner">
                    <img
                        className="header-logo"
                        src="/keel-mind-logo.webp"
                        alt="Keel mind logo"
                    />
                    <div className="content">
                        <div className="caccf-top-flex">
                            <div>
                                <h1>Receive free counselling</h1>
                                <p className="p-bigger">
                                    With certified addiction counsellors in
                                    Canada
                                </p>
                            </div>
                            <div
                                id="caccf-hero-controls"
                                className="hero-controls"
                            >
                                <button
                                    onClick={toggleAnimationHandler}
                                    aria-label={`${
                                        animationState ? "Pause" : "Resume"
                                    } background video`}
                                >
                                    <img
                                        src={`/${
                                            animationState ? "pause" : "play"
                                        }-icon.png`}
                                        alt=""
                                    />
                                </button>
                            </div>
                        </div>
                        <CTAButton
                            href={
                                "https://app.ca.geta-head.com/app/gah/#/register/orgcode/mcF6"
                            }
                        >
                            Register now
                        </CTAButton>
                        <a
                            id="caccf-login"
                            href="https://app.ca.geta-head.com/app/gah/#/"
                            className="outline-button white"
                        >
                            Login
                        </a>
                    </div>
                </div>
            </VideoSection>

            <div className="steps-section">
                <h3>Get help now by completing the following steps</h3>
                <div class="steps-container">
                    <div className="caccf-step">
                        <img src="/step1_icon.png" alt="" />
                        <h5>Register your account</h5>
                        <p>
                            <a
                                href="https://app.ca.geta-head.com/app/gah/#/register/orgcode/mcF6"
                                className="orange"
                                aria-label="Click here to begin the registration process"
                            >
                                Click here
                            </a>{" "}
                            <span aria-hidden="true">
                                to begin the registration process.
                            </span>
                        </p>
                    </div>
                    <div className="caccf-step">
                        <img src="/step2_icon.png" alt="" />
                        <h5>Select a counsellor</h5>
                        <p>
                            Choose a counsellor from a list of recommended
                            matches.
                        </p>
                    </div>
                    <div className="caccf-step">
                        <img src="/step3_icon.png" alt="" />
                        <h5>Schedule your session</h5>
                        <p>
                            Book your first session now and start feeling better
                            today.
                        </p>
                    </div>
                </div>
            </div>

            <div className="resources-section">
                <h3
                    style={{
                        fontSize: 20,
                        lineHeight: "34px",
                    }}
                >
                    Resources
                </h3>
                <div className="resource-links-container">
                    <a
                        href="mailto:techsupport@caccf.ca?subject=Keel%20Mind%20Support"
                        className="outline-button big"
                        aria-label="Contact support: compose email to techsupport@caccf.ca"
                    >
                        Contact support
                    </a>
                    <a
                        href="https://geta-head.zendesk.com/hc/en-us/articles/30744292392205"
                        className="outline-button big"
                        aria-label="Visit frequently asked questions help page"
                    >
                        FAQ
                    </a>
                    <a
                        href="https://geta-head.zendesk.com/hc/en-us/sections/29944109043981"
                        className="outline-button big"
                        aria-label="Visit training videos help page"
                    >
                        Training videos
                    </a>
                </div>
            </div>
        </div>
    );
}

export default CACCFPage;
