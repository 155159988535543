import { useRef } from "react";
import "../css/AccordionItem.scss";

function AccordionItem({ title, description, id }) {
    const ref = useRef(null);

    function handleHeadingClick() {
        if (ref.current.classList.contains("open")) {
            ref.current.classList.remove("open");
            ref.current.querySelector(".body-container").style.maxHeight = 0;
            ref.current
                .querySelector(".heading-text")
                .setAttribute("aria-expanded", false);
        } else {
            if (document.querySelector(".accordion-item.open")) {
                const otherOpen = document.querySelector(
                    ".accordion-item.open"
                );

                otherOpen.querySelector(".body-container").style.maxHeight = 0;

                otherOpen
                    .querySelector(".heading-text")
                    .setAttribute("aria-expanded", false);

                otherOpen.classList.remove("open");
            }

            ref.current.classList.add("open");
            ref.current.querySelector(".body-container").style.maxHeight = `${
                ref.current
                    .querySelector(".body-container p")
                    .getBoundingClientRect().height
            }px`;

            ref.current
                .querySelector(".heading-text")
                .setAttribute("aria-expanded", true);
        }
    }

    function handleHeadingKey(event) {
        if (event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            handleHeadingClick();
        }
    }

    return (
        <div className="accordion-item" id={id} ref={ref}>
            <div
                tabIndex={0}
                onClick={handleHeadingClick}
                onKeyDown={handleHeadingKey}
                role="heading"
                aria-level={3}
                className="heading-wrapper"
            >
                <div
                    id={id + "-button"}
                    className="heading-text"
                    role="button"
                    aria-expanded={false}
                    aria-controls={id + "-panel"}
                >
                    <strong>{title}</strong>
                    <img src="/arrow-down-icon.svg" alt="" />
                </div>
            </div>
            <div
                className="body-container"
                id={id + "-panel"}
                role="region"
                aria-labelledby={id + "-button"}
            >
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
        </div>
    );
}

export default AccordionItem;
